var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c("page-title", { attrs: { "show-back-btn": "" } }),
      _c("div", { staticClass: "partition-area" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading.detail,
                expression: "loading.detail"
              }
            ],
            staticClass: "partition-area"
          },
          [
            _c(
              "el-form",
              {
                ref: "appForm",
                attrs: {
                  model: _vm.appForm,
                  rules: _vm.rules,
                  "label-width": "180px",
                  "label-suffix": _vm.constants.labelSuffix
                }
              },
              [
                _c(
                  "ics-page-inner",
                  { attrs: { title: "额度申请进度" } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-steps",
                          {
                            attrs: {
                              active: _vm.activeIndex,
                              "finish-status": "success"
                            }
                          },
                          [
                            _c("el-step", {
                              attrs: {
                                title: "步骤 1",
                                description: "发起申请"
                              }
                            }),
                            _c("el-step", {
                              attrs: {
                                title: "步骤 2",
                                description: "资方审批"
                              }
                            }),
                            _c("el-step", {
                              attrs: { title: "步骤 3", description: "完成" }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "ics-page-inner",
                  { attrs: { title: "申请信息" } },
                  [
                    this.$route.params.editMode !== "add"
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "申请编号" } },
                              [
                                _c("p", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.utils.isEffectiveCommon(
                                        _vm.appForm.quotaCode
                                      )
                                    )
                                  )
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "financeType", label: "产品类型" } },
                          [
                            _c(
                              "ics-item-inner",
                              {
                                attrs: {
                                  prop: _vm.appForm.financeType,
                                  format: function(val) {
                                    return _vm.utils.statusFormat(
                                      Number(val),
                                      "productType"
                                    )
                                  }
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择产品类型",
                                      filterable: ""
                                    },
                                    model: {
                                      value: _vm.appForm.financeType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.appForm,
                                          "financeType",
                                          $$v
                                        )
                                      },
                                      expression: "appForm.financeType"
                                    }
                                  },
                                  _vm._l(_vm.constants.productType, function(
                                    item
                                  ) {
                                    return _c("el-option", {
                                      key: item.value,
                                      attrs: {
                                        label: item.label,
                                        value: item.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    this.$route.params.editMode === "add"
                      ? _c("el-col")
                      : _vm._e(),
                    !_vm.viewEdit()
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "申请企业名称" } },
                              [
                                _c("ics-item-inner", {
                                  attrs: {
                                    prop: _vm.appForm.coreName,
                                    format: _vm.utils.isEffectiveCommon
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.viewEdit()
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { prop: "coreId", label: "申请企业名称" }
                              },
                              [
                                _c(
                                  "ics-item-inner",
                                  {
                                    attrs: {
                                      prop: _vm.appForm.coreId,
                                      format: _vm.utils.isEffectiveCommon
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择核心企业",
                                          filterable: ""
                                        },
                                        on: {
                                          change: function(val) {
                                            return _vm.companyChange(
                                              val,
                                              "core"
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.appForm.coreId,
                                          callback: function($$v) {
                                            _vm.$set(_vm.appForm, "coreId", $$v)
                                          },
                                          expression: "appForm.coreId"
                                        }
                                      },
                                      _vm._l(_vm.coreList, function(item) {
                                        return _c("el-option", {
                                          key: item.coreId,
                                          attrs: {
                                            label:
                                              item.coreName +
                                              "（" +
                                              item.coreCode +
                                              "）",
                                            value: item.coreId
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "统一社会信用代码" } },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(
                                    _vm.appForm.coreSocialNo
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    !_vm.viewEdit()
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "资金方名称" } },
                              [
                                _c("ics-item-inner", {
                                  attrs: {
                                    prop: _vm.appForm.capName,
                                    format: _vm.utils.isEffectiveCommon
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.viewEdit()
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "capId", label: "资金方名称" } },
                              [
                                _c(
                                  "ics-item-inner",
                                  {
                                    attrs: {
                                      prop: _vm.appForm.capId,
                                      format: _vm.utils.isEffectiveCommon
                                    }
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "请选择所属资金方",
                                          filterable: ""
                                        },
                                        on: {
                                          change: function(val) {
                                            return _vm.companyChange(val, "cap")
                                          }
                                        },
                                        model: {
                                          value: _vm.appForm.capId,
                                          callback: function($$v) {
                                            _vm.$set(_vm.appForm, "capId", $$v)
                                          },
                                          expression: "appForm.capId"
                                        }
                                      },
                                      _vm._l(_vm.capList, function(item) {
                                        return _c("el-option", {
                                          key: item.capId,
                                          attrs: {
                                            label:
                                              item.capName +
                                              "（" +
                                              item.capCode +
                                              "）",
                                            value: item.capId
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "统一社会信用代码" } },
                          [
                            _c("p", [
                              _vm._v(
                                _vm._s(
                                  _vm.utils.isEffectiveCommon(
                                    _vm.appForm.capSocialNo
                                  )
                                )
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop: "applyAmount",
                              label: "申请额度(元)"
                            }
                          },
                          [
                            _c(
                              "ics-item-inner",
                              {
                                attrs: {
                                  prop: _vm.appForm.applyAmount,
                                  format: function(val) {
                                    return _vm.utils.moneyFormat(Number(val), 2)
                                  }
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "number",
                                    placeholder: "请输入申请额度"
                                  },
                                  model: {
                                    value: _vm.appForm.applyAmount,
                                    callback: function($$v) {
                                      _vm.$set(_vm.appForm, "applyAmount", $$v)
                                    },
                                    expression: "appForm.applyAmount"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { prop: "passAmount", label: "批复额度" } },
                          [
                            _c(
                              "ics-item-inner",
                              {
                                attrs: {
                                  prop: _vm.appForm.passAmount,
                                  format: function(val) {
                                    return _vm.utils.moneyFormat(Number(val), 2)
                                  }
                                }
                              },
                              [
                                _c("el-input", {
                                  attrs: {
                                    type: "number",
                                    placeholder: "请输入批复额度"
                                  },
                                  model: {
                                    value: _vm.appForm.passAmount,
                                    callback: function($$v) {
                                      _vm.$set(_vm.appForm, "passAmount", $$v)
                                    },
                                    expression: "appForm.passAmount"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.$route.params.editMode === "add"
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { prop: "deadline", label: "截至日期" }
                              },
                              [
                                _c(
                                  "ics-item-inner",
                                  {
                                    attrs: {
                                      prop: _vm.appForm.deadline,
                                      format: function(val) {
                                        return _vm.utils.dateFormat(val)
                                      }
                                    }
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        type: "date",
                                        "value-format": "yyyy-MM-dd HH:mm:ss",
                                        placeholder: "选择日期",
                                        "picker-options": _vm.pickerOptions
                                      },
                                      model: {
                                        value: _vm.appForm.deadline,
                                        callback: function($$v) {
                                          _vm.$set(_vm.appForm, "deadline", $$v)
                                        },
                                        expression: "appForm.deadline"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.$route.params.editMode !== "add"
                      ? _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c("el-form-item", { attrs: { label: "有效期" } }, [
                              _c("p", [
                                _vm._v(
                                  _vm._s(
                                    _vm.appForm.createdAt === undefined
                                      ? ""
                                      : _vm.appForm.createdAt +
                                          " ~ " +
                                          _vm.appForm.deadline
                                  )
                                )
                              ])
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "附件" } },
                          [
                            _vm.viewEdit()
                              ? _c(
                                  "p",
                                  [
                                    _c(
                                      "debounce-button",
                                      {
                                        attrs: { type: "primary" },
                                        on: { click: _vm.clickDialog }
                                      },
                                      [_vm._v(" 点击上传 ")]
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._l(_vm.appForm.files, function(item, index) {
                              return _c("p", { key: index }, [
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-btn",
                                    attrs: { href: "javascript:" },
                                    on: {
                                      click: function($event) {
                                        return _vm.utils.downloadP(
                                          "other",
                                          item.url
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                ),
                                _vm.viewEdit()
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "text-btn danger",
                                        attrs: { href: "javascript:" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteFile(index)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete"
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            })
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _vm.viewEdit()
                      ? _c("ics-button-inner", {
                          attrs: {
                            loading: _vm.loading.submit,
                            "submit-title": "提交"
                          },
                          on: { submit: _vm.submitForms }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("ics-common-upload-inner", {
        attrs: { "upload-dialog": _vm.dialog.upload, bucket: "other" },
        on: { onSuccessFile: _vm.onSuccessFile }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }