<template>
  <el-main>
    <page-title show-back-btn />
    <div class="partition-area">
      <div v-loading="loading.detail" class="partition-area">
        <el-form ref="appForm" :model="appForm" :rules="rules" label-width="180px" :label-suffix="constants.labelSuffix">
          <ics-page-inner title="额度申请进度">
            <el-col :span="24">
              <el-steps :active="activeIndex" finish-status="success">
                <el-step title="步骤 1" description="发起申请" />
                <el-step title="步骤 2" description="资方审批" />
                <el-step title="步骤 3" description="完成" />
              </el-steps>
            </el-col>
          </ics-page-inner>
          <ics-page-inner title="申请信息">
            <el-col v-if="this.$route.params.editMode !== 'add'" :span="12">
              <el-form-item label="申请编号">
                <p>{{ utils.isEffectiveCommon(appForm.quotaCode) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="financeType" label="产品类型">
                <ics-item-inner :prop="appForm.financeType" :format="(val)=>utils.statusFormat(Number(val), 'productType')">
                  <el-select v-model="appForm.financeType" placeholder="请选择产品类型" filterable>
                    <el-option v-for="item in constants.productType" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col v-if="this.$route.params.editMode === 'add'" />
            <el-col v-if="!viewEdit()" :span="12">
              <el-form-item label="申请企业名称">
                <ics-item-inner :prop="appForm.coreName" :format="utils.isEffectiveCommon" />
              </el-form-item>
            </el-col>
            <el-col v-if="viewEdit()" :span="12">
              <el-form-item prop="coreId" label="申请企业名称">
                <ics-item-inner :prop="appForm.coreId" :format="utils.isEffectiveCommon">
                  <el-select v-model="appForm.coreId" placeholder="请选择核心企业" filterable @change="(val)=>companyChange(val,'core')">
                    <el-option v-for="item in coreList" :key="item.coreId" :label="`${item.coreName}（${item.coreCode}）`" :value="item.coreId" />
                  </el-select>
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统一社会信用代码">
                <p>{{ utils.isEffectiveCommon(appForm.coreSocialNo) }}</p>
              </el-form-item>
            </el-col>
            <el-col v-if="!viewEdit()" :span="12">
              <el-form-item label="资金方名称">
                <ics-item-inner :prop="appForm.capName" :format="utils.isEffectiveCommon" />
              </el-form-item>
            </el-col>
            <el-col v-if="viewEdit()" :span="12">
              <el-form-item prop="capId" label="资金方名称">
                <ics-item-inner :prop="appForm.capId" :format="utils.isEffectiveCommon">
                  <el-select v-model="appForm.capId" placeholder="请选择所属资金方" filterable @change="(val)=>companyChange(val,'cap')">
                    <el-option v-for="item in capList" :key="item.capId" :label="`${item.capName}（${item.capCode}）`" :value="item.capId" />
                  </el-select>
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="统一社会信用代码">
                <p>{{ utils.isEffectiveCommon(appForm.capSocialNo) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="applyAmount" label="申请额度(元)">
                <ics-item-inner :prop="appForm.applyAmount" :format="(val)=>utils.moneyFormat(Number(val), 2)">
                  <el-input v-model="appForm.applyAmount" type="number" placeholder="请输入申请额度" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="passAmount" label="批复额度">
                <ics-item-inner :prop="appForm.passAmount" :format="(val)=>utils.moneyFormat(Number(val), 2)">
                  <el-input v-model="appForm.passAmount" type="number" placeholder="请输入批复额度" />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col v-if="$route.params.editMode === 'add'" :span="12">
              <el-form-item prop="deadline" label="截至日期">
                <ics-item-inner :prop="appForm.deadline" :format="(val)=>utils.dateFormat(val)">
                  <el-date-picker
                    v-model="appForm.deadline"
                    type="date"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 100%"
                    placeholder="选择日期"
                    :picker-options="pickerOptions"
                  />
                </ics-item-inner>
              </el-form-item>
            </el-col>
            <el-col v-if="$route.params.editMode !== 'add'" :span="12">
              <el-form-item label="有效期">
                <p>{{ appForm.createdAt===undefined ? '' : (appForm.createdAt + ' ~ ' + appForm.deadline) }}</p>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="附件">
                <p v-if="viewEdit()">
                  <debounce-button type="primary" @click="clickDialog">
                    点击上传
                  </debounce-button>
                </p>
                <p v-for="(item, index) in appForm.files" :key="index">
                  <a href="javascript:" class="text-btn" @click="utils.downloadP('other', item.url)">{{ item.name }}</a>
                  <a v-if="viewEdit()" href="javascript:" class="text-btn danger" @click="deleteFile(index)"><i class="el-icon-delete" /></a>
                </p>
              </el-form-item>
            </el-col>
            <ics-button-inner v-if="viewEdit()" :loading="loading.submit" submit-title="提交" @submit="submitForms" />
          </ics-page-inner>
        </el-form>
      </div>
    </div>
    <ics-common-upload-inner :upload-dialog="dialog.upload" bucket="other" @onSuccessFile="onSuccessFile" />
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsCommonUploadInner from '@/components/common-upload-inner'
export default {
  components: {IcsCommonUploadInner},
  mixins: [routeEnterMixin],
  data () {
    return {
      id: this.$route.query.id,
      appForm: {
        financeType: '',
        coreCode: '',
        coreName: '',
        coreSocialNo: '',
        capCode: '',
        capName: '',
        capSocialNo: '',
        applyAmount: '',
        passAmount: '',
        files: []
      },
      dialog: {
        upload: {
          visible: false,
          title: '上传附件'
        }
      },
      activeIndex: 0,
      capList: [],
      coreList: [],
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      rules: {
        financeType: [
          { required: true, message: '请选择产品类型', trigger: 'change' }
        ],
        coreId: [
          { required: true, message: '请选择申请企业名称', trigger: 'change' }
        ],
        capId: [
          { required: true, message: '请选择资金方名称', trigger: 'change' }
        ],
        applyAmount: [
          { required: true, message: '请输入申请额度', trigger: 'blur' }
        ],
        passAmount: [
          { required: true, message: '请输入批复额度', trigger: 'blur' }
        ],
      }
    }
  },
  created () {
    if (this.id) {
      this.getDetail()
    }
    if (this.viewEdit()) {
      this.getCoreList()
      this.getCapList()
    }
  },
  methods: {
    getDetail () {
      this.loading.detail = true
      this.api.quota.core.quotaDetail(this.id).then(result => {
        this.appForm = result.data.data || {}
        switch (this.appForm.applyStatus) {
          case 0:
            this.activeIndex = 0
            break
          case 1:
            this.activeIndex = 1
            break
          case 2:
            this.activeIndex = 3
            break
          case 3:
            this.activeIndex = 1
            break
          case 4:
            this.activeIndex = 3
            break
          default:
            this.activeIndex = 0
        }
      }).finally(() => {
        this.loading.detail = false
      })
    },
    getCoreList () {
      this.api.company.core.getCoreList().then(result => {
        this.coreList = result.data.data || []
      }).finally(() => {
      })
    },
    getCapList () {
      this.api.company.capital.getCapList().then(result => {
        this.capList = result.data.data || []
      }).finally(() => {
      })
    },
    companyChange (val, type) {
      const obj = {}
      if (type === 'core') {
        const data = this._.find(this.coreList, { coreId: val }) || {}
        obj[`${type}Code`] = data.coreCode
        obj[`${type}Name`] = data.coreName
        obj[`${type}SocialNo`] = data.coreSocialNo
      } else {
        const data = this._.find(this.capList, { capId: val }) || {}
        obj[`${type}Code`] = data.capCode
        obj[`${type}Name`] = data.capName
        obj[`${type}SocialNo`] = data.capSocialNo
      }
      this.appForm = this._.assign(this.appForm, obj)
    },
    clickDialog () {
      this.dialog.upload.visible = true
    },
    onSuccessFile (response) {
      response.data.url = response.data.path
      console.log(this.appForm.files.length)
      if (this.appForm.files.length > 5) {
        this.$message.error('最多能上传6个文件')
        return false
      }
      this.appForm.files.push(response.data)
    },
    deleteFile (index) {
      this.appForm.files.splice(index, 1)
    },
    reportUrlFilesSuccess (response, file) {
      response.data.url = response.data.path
      this.appForm.files.push(response.data)
    },
    reportUrlFilesRemove (file) {
      this.appForm.files = this.appForm.files.filter(function (item) {
        return item.url !== file.url
      })
    },
    previewFile (file) {
      this.utils.downloadP('other', file.path)
    },
    submitForms () {
      this.$refs.appForm.validate().then(() => {
        const data = this._.cloneDeep(this.appForm)
        this.loading.submit = true
        this.api.quota.core.addQuota(data).then(result => {
          if (result.data.success === true) {
            this.loading.submit = false
            this.$message.success('操作成功')
            this.$router.back()
          } else {
            this.$message.error(result.data.message)
          }
        }).catch(e => {
          this.loading.submit = false
        })
      })
    }
  }
}
</script>

<style scoped>
</style>
